import '../node_modules/milligram/src/milligram.sass'
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import './styles/main.styl'

function bgFader() {
    const opacity = Math.max(window.innerHeight - window.scrollY, 0) / window.innerHeight
    document.documentElement.style.setProperty('--bg-opacity', `${opacity}`)
}

document.addEventListener('scroll', bgFader)
